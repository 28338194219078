:root {
    --off: #8b0000;
    --on: #006400
}

@media (prefers-color-scheme: dark) {
    :root {
        --off: red;
        --on: #0c0
    }
}

nav {
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
    padding: 0.7rem 0
}

#title {
    font-size: 150%;
    font-weight: 700;
    background-color: var(--bg);
    color: var(--accent);
    padding: 0;
}

#title:disabled {
    color: var(--text-light);
}

#toolbar {
    margin: auto 0;
}

#toolbar > button {
    font-size: 80%;
    background-color: var(--accent-bg);
    color: var(--text);
    padding: 0.3rem 0.5rem;
}

#toolbar > button:disabled {
    background-color: var(--bg);
    color: var(--text-light);
}

#account {
    margin: auto 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

body > footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.2;
    padding: 1rem 0;
    margin: unset;
    border: unset;
}

#gpt {
    flex: none;
    font-size: 80%;
    background-color: var(--accent-bg);
    color: var(--text-light);
    padding: 0.3rem 0.5rem;
    border: 1px solid var(--on);
}

#gpt:disabled {
    border: 1px solid var(--off);
}

#statusbar {
    flex: 1;
    font-size: 80%;
    color: var(--text-light);
    padding: 0.3rem 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#statusbar > span {
    padding-left: 0.5rem;
}

#tips {
    flex: none;
    font-size: 80%;
    background-color: var(--accent-bg);
    color: var(--text-light);
    padding: 0.3rem 0.5rem 0.3rem 0;
}

#tips > kbd {
    margin-right: 0.5rem;
}

#viewer {
    overflow: auto;
}

@media (max-width: 500px) {
    nav {
        flex-wrap: wrap;
    }

    #title {
        margin-bottom: 0.5rem;
    }

    #toolbar {
        text-align: left;
    }

    #account {
        text-align: right;
    }

    #tips {
        padding: 0.3rem 0.5rem;
    }

    #tips > kbd {
        display: none;
    }
}
